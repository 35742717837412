import React, { useEffect, useState } from "react";
import { Badge, Icon, Loader, Modal, ViewContext } from 'components/lib';
import PropTypes from "prop-types";
import axios from "axios";
import { BusinessModels } from "../views/metrix/businessModels";
import { useContext } from "react";

const StepIndicator = ({ steps, activeStep, setActiveSteps }) => (
    <div className='grid grid-cols-6 gap-4'>
        {steps.map((step, index) => (
            <a key={`${step.index}-${step.category}`} className="flex flex-col items-center cursor-pointer"
                onClick={() => setActiveSteps(index)}>
                <div
                    className={`
                        relative flex items-center justify-center w-10 h-10 rounded-full
                        ${index === activeStep ? 'bg-derisky-rose border-3 border-brand-400' : ''}
                        ${index <= activeStep ? 'bg-brand-400 shadow-inner text-white' : 'bg-white text-brand-400 shadow-md border border-brand-400'}
                        hover:shadow-inner group
    `               }
                >
                    <Icon
                        color={index <= activeStep ? 'text-white' : 'text-gray'}
                        image={step.icon}
                    />
                </div>
                <div className="mt-2 text-center text-sm brand-400">
                    {step.category}
                </div>
            </a>
        ))}
    </div>
);

const ActionButtons = ({ activeStep, setActiveSteps, formData, sendForm, saveForm, setLoading, loading }) => {
    const [showSend, setShowSend] = useState(false);
    const [showSave, setShowSave] = useState(false);

    useEffect(() => {
        const isAllValuesLongEnough = formData?.map(form => form.fields).flat().every(e => e.value.length >= 20);
        const anyValueLengthGreaterThan20 = formData?.map(form => form.fields).flat().some(e => e.value.length > 20);

        setShowSend(isAllValuesLongEnough);
        setShowSave(anyValueLengthGreaterThan20);
    }, [formData]);

    return (
        <div className='py-10 flex gap-2'>
            <button
                className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center ${activeStep === 0 && 'opacity-25'}`}
                disabled={activeStep === 0} onClick={() => setActiveSteps(activeStep - 1)}>
                <Icon image='arrow-left' />
                {activeStep > 0 && `${activeStep} . ${formData[activeStep - 1]?.category || "ㅤ"} `}
            </button>
            <button
                className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center ${true && 'opacity-25'}`}
                disabled={true} onClick={saveForm}>
                <Icon image='save' />
                Save <Badge className='text-xs' color='green' text='🔜'></Badge>
            </button>
            <button
                className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center ${!showSend && 'opacity-25'}`}
                disabled={loading || !showSend}
                onClick={sendForm}>
                <Icon image='send' />
                Send
            </button>
            <button disabled={activeStep == 5 || activeStep === formData.length}
                className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center ${activeStep === formData.length - 1 && 'opacity-25'}`}
                onClick={() => setActiveSteps(activeStep + 1)}>
                {activeStep !== formData.length - 1 ? `${activeStep + 1} . ${formData[activeStep + 1]?.category}` : "ㅤ"}
                <Icon image='arrow-right' />
            </button>
        </div>
    );
}

const FormContent = ({ activeStep, steps, setFormData, toggleLoading }) => {
    const changeField = (e, index) => {
        const updatedSteps = [...steps];
        updatedSteps[activeStep].fields[index].value = e.target.value;
        setFormData(updatedSteps);
    }

    return (
        <div className='mt-5'>
            <h2 className='flex items-center gap-4'>
                <Icon image={steps[activeStep]?.icon} />
                {steps[activeStep]?.category}
            </h2>
            {steps[activeStep].fields.map((field, index) => (
                <div key={index}>
                    <h4 className='my-4'>{field.name}: {field.label}</h4>
                    {field.img && <img src={field.img} />}
                    <p className='text-sm'>{field.description}</p>
                    <textarea id={field.label} rows={6}
                        className='border-brand-400 text-md border rounded-md text-sm p-3 w-full' required
                        name={field.name} value={field.value} onChange={(e) => changeField(e, index)} />
                </div>
            ))}
        </div>
    );
}

const MyStepWizard = ({ id, team_id, case_id, toggleLoading, unLoading, close, product_id, responseData, doc_id }) => {
    const [formData, setFormData] = useState([
        {
            category: 'Customer', icon: 'crosshair', fields: [
                {
                    id: "customer_description_and_buying_decisions",
                    name: "Customer",
                    label: "Who are your customers?",
                    description: 'Be as specific as possible. Are there multiple segments? Describe them as precise as possible. What is their secret desire? What is their interpretation of growth? What are their demographics, habits, jobs, tasks.',
                    value: ''
                },
                { id: "business_critical_problems", name: "Problem", label: "Problem", value: '' }
            ]
        },
        {
            category: 'Solution', icon: 'gift', fields: [
                { id: "features_and_capabilities", name: "Features", label: "What are the most important features and capabilities of your solution?", value: '' }
            ]
        },
        {
            category: 'Market', icon: 'maximize', fields: [
                { id: "prospect_alternative_options", name: "Competitors", label: "What are your prospect’s alternative options?", value: '' },
                { id: "problem_and_solution_category", name: "Category", label: "What category of problem and solution do you fall into?", value: '' }
            ]
        },
        {
            category: 'USP', icon: 'arrow-up-right', fields: [
                { id: 'unique_value_proposition', name: 'Unique Selling Point', label: 'What is your unique value proposition and unfair advantage?', value: '' },
                { id: "trends_and_trigger_events", name: "Trends", label: "What trends and trigger events cause your prospects to search for solutions?", value: '' }
            ]
        },
        {
            category: 'Go to market', icon: 'speaker', fields: [
                { id: "go_to_market_strategy", name: "Go-to-market", label: "What is your go-to market strategy?", value: '' },
                { id: "costs_of_sale_and_sources_of_revenue", name: "Revenue", label: "What are your key costs of sale and sources of revenue?", value: '' }
            ]
        },
        {
            category: 'Misc', icon: 'target', fields: [
                { id: "key_metrics_to_manage_business", name: "Key Metrics", label: "What are the key metrics you use to manage the business?", value: '' },
                { id: "core_business", name: "Core Business", label: "Core Business", value: '' },
                { id: "additional_notes", name: "Notes", label: "Anything to add.", value: '' }
            ]
        }
    ]);
    const [loading, setLoading] = useState('')
    const [responnseSuccess, setResponnseSuccess] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const context = useContext(ViewContext)

    const [activeStep, setActiveStep] = useState(0);
    const saveForm = () => {
        const url = case_id ? `/api/business_model/${case_id}` : '/api/business_model';
        const data = formData.flatMap(cat => cat.fields).reduce((acc, e) => {
            acc[e.id] = e.value;
            return acc;
        }, {});

        console.log(data);
    }

    useEffect(() => {
        if (doc_id)
            handleQuestionSubmit()
    }, [doc_id])


    const handleQuestionSubmit = async () => {
        setLoading(true);
        setResponnseSuccess(false);
        try {
            // Validate form data
            if (!product_id) {
                alert('Please select a document first');
                return;
            }

            const updatedFormData = [...formData];

            // Loop through each category and its fields
            for (const category of updatedFormData) {
                for (const field of category.fields) {
                    const question = field.label;
                    if (question?.trim()?.length < 3) {
                        console.log(`Skipping short question: ${question}`);
                        continue;
                    }

                    try {
                        const res = await axios.post('/api/document_query', {
                            user_id: user?.user_id,
                            doc_id: doc_id,
                            team_id: product_id,
                            question: question,
                            k: 20,
                        });

                        const answer = res?.data?.data?.answer || 'No response received.';
                        field.value = answer;
                    } catch (err) {
                        console.error(`Error fetching response for question: ${question}`, err);
                        field.value = 'Error fetching response.';
                    }
                }
            }

            setFormData(updatedFormData);
            setResponnseSuccess(true);
        } catch (error) {
            console.error('Error processing questions:', error);

        } finally {
            setLoading(false);
        }
    };

    const sendForm = async () => {
        toggleLoading();
        setLoading(true);
        const url = case_id ? '/api/business_model' : `/api/business_model/${case_id}`;
        const data = formData.flatMap(cat => cat.fields).reduce((acc, e) => {
            acc[e.id] = e.value;
            return acc;
        }, {});

        // Check for missing required fields
        const requiredFields = [
            'business_critical_problems',
            'customer_description_and_buying_decisions',
            'trends_and_trigger_events',
            'features_and_capabilities',
            'prospect_alternative_options',
            'problem_and_solution_category',
            'unique_value_proposition',
            'go_to_market_strategy',
            'costs_of_sale_and_sources_of_revenue',
            'key_metrics_to_manage_business'
        ];

        const missingFields = requiredFields.filter(field => !data[field] || data[field].trim() === '');

        if (missingFields.length > 0) {
            alert(`The following fields are required: ${missingFields.join(', ')}`);
            toggleLoading();
            setLoading(false);
            return;
        }

        const payload = {
            ...data,
            team_id,
            case_id,
            isDoc: doc_id ? true : false,
            id: product_id
        };

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        try {
            const res = await axios.post(url, payload, { headers });
            setLoading(false);
            console.log(res.data);
            responseData && responseData(res.data)
            unLoading();
            context.notification.hide()
            close(res.data);
        } catch (error) {
            unLoading();
            setLoading(false);

            if (error.response?.data?.error?.message) {
                context.notification.show(error.response?.data?.error?.message, 'error', false)
            } else if (error.response?.data?.message) {
                context.notification.show(error.response?.data?.message, 'error', true)
            }

            console.error('Error sending form data:', error.response?.data?.error?.message, error.response?.data || error.message);

        } finally {
            setLoading(false);
        }

    };


    return (
        <div className='min-w-fit overflow-y-scroll' style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            {loading && <Loader />}
            <StepIndicator steps={formData} activeStep={activeStep} setActiveSteps={setActiveStep} />
            <FormContent steps={formData} activeStep={activeStep} setFormData={setFormData} />
            <ActionButtons loading={loading} activeStep={activeStep} formData={formData} unLoading={unLoading} sendForm={sendForm} saveForm={saveForm}
                setActiveSteps={setActiveStep} />
        </div>
    );
};

const Wrapper = (props) => (
    props.modal && (
        <Modal
            className='w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/4'
            isOpen={props.modal}
            wizard={<MyStepWizard
                className='sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/4'
                unLoading={props.unLoading}
                toggleLoading={props.toggleLoading}
                id={props.id}
                close={props.close}
                team_id={props.product?.team_id}
                case_id={props.case_id}
                doc_id={props?.doc_id}
                responseData={props?.responseData}
                product_id={props.product_id}
            />}
            hight={'80vh'}
            title='AI Kickstart'
            action={() => props.close()}
        />
    )
);

MyStepWizard.propTypes = {
    id: PropTypes.any,
    team_id: PropTypes.any,
    case_id: PropTypes.any,
    toggleLoading: PropTypes.func,
    unLoading: PropTypes.func,
    product_id: PropTypes.any
};

ActionButtons.propTypes = {
    activeStep: PropTypes.number,
    setActiveSteps: PropTypes.func,
    setLoading: PropTypes.func,
    formData: PropTypes.array,
    sendForm: PropTypes.func,
    saveForm: PropTypes.func
};

FormContent.propTypes = {
    toggleLoading: PropTypes.func,
    unLoading: PropTypes.func,
    activeStep: PropTypes.number,
    steps: PropTypes.array,
    setFormData: PropTypes.func
};

Wrapper.propTypes = {
    toggleLoading: PropTypes.func,
    modal: PropTypes.bool,
    id: PropTypes.any,
    product: PropTypes.object,
    close: PropTypes.func,
    unLoading: PropTypes.func
};

export default Wrapper;
